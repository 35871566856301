import axios from "axios";

const API_ENDPOINT = process.env.LOCAL_DJANGO ? `http://127.0.0.1:8000` : `https://admin.magyk.cloud`;

export default class DataService {
  static getCMSData = endpoint => {
    let data = Promise.resolve(
      axios.get(`${API_ENDPOINT}/api/${endpoint}`)
    )
      .then(response => {
        return response.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function (response) {
        // always executed
        return response;
      });
    return data;
  };
}
